<template>
    <v-container>
        <amp-banner v-if="$vuetify.breakpoint.mdAndUp" class="my-4" />

        <v-container class="px-0">
            <v-row class="justify-space-between">
                <v-col cols="12" md="auto">
                    <div class="text-h6 text-md-h5 font-weight-bold">
                        My Assignments
                    </div>
                </v-col>
                <v-col cols="12" md="auto" class="d-flex">
                    <v-switch
                        v-model="showLimitedDistribution"
                        label="Selective Distribution"
                        :disabled="isLoading"
                        inset
                        hide-details
                        class="mt-0"
                    />
                </v-col>
            </v-row>
        </v-container>

        <assignments-list
            :assignments="assignments"
            :loading="isLoading"
            :updating="isUpdating"
            class="px-0 pt-0"
            @update="update"
        />

        <paging
            :total="total"
            :default-per-page="perPage"
            :loading="isUpdating"
            size="10"
            class="main-background px-0"
        />

        <training-section class="mt-10" />

        <training-link v-if="canSeeTrainingLink && !isLoading" class="mt-4" />
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { mapGetters } from 'vuex';

import { ListPaged, Training, UserId } from '@/mixins';
import { ProvideReactive } from '@/utils/decorators';

import { AmpBanner } from '@/components/AmpBanner';
import { Paging } from '@/components/Paging';
import { AssignmentsList } from '@/components/Authoring/AssignmentsList';
import { TrainingLink, TrainingSection } from '@/components/Training';

import type { Assignment } from '@/types/Assignment';

@Component<AuthorAssignments>({
    components: {
        AmpBanner,
        AssignmentsList,
        Paging,
        TrainingSection,
        TrainingLink
    },
    computed: {
        ...mapGetters('user', ['isEditor'])
    }
})
export default class AuthorAssignments extends mixins(
    ListPaged,
    Training,
    UserId
) {
    isEditor!: boolean;

    endpoint = '/author_requests_pool/assignments';

    assignments: Assignment[] = [];

    @ProvideReactive('hideAuthor')
    get hideAuthorProvider() {
        return this.isAuthor || this.isEditor;
    }

    get queryParams() {
        return {
            user_id: this.userId,
            limit: 10,
            page: 1,
            sort: 'priority',
            asc: 1,
            showLimitedDistribution: 0
        };
    }

    get showLimitedDistribution() {
        return this.$route.query.showLimitedDistribution === '1';
    }

    set showLimitedDistribution(showLimitedDistribution: boolean) {
        this.$router.push({
            path: this.$route.path,
            query: {
                ...this.$route.query,
                showLimitedDistribution: showLimitedDistribution ? '1' : void 0
            }
        });
    }

    onData(data: { assignments: Assignment[]; length: number }) {
        this.assignments = data.assignments;
        this.total = data.length;
    }
}
</script>
